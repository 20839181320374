import './styles/homepage.scss';

// please keep instant.page for all pages
import 'instant.page';

// import setupOurVetsSlider from './js/our-vets-slider';
// import setupReviewsLineSlider from './js/reviews-line-slider';
import setupMobileNav from './js/mobile-nav';

document.addEventListener( 'DOMContentLoaded', function() {
	// setupReviewsLineSlider();
	// setupOurVetsSlider();
	setupMobileNav();
} );

// window.addEventListener( 'resize', function() {
// 	setupOurVetsSlider();
// 	setupReviewsLineSlider();
// } );
